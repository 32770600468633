import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useForm } from "react-hook-form"
import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const TrialPage = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {
    fetch("https://v5.dev.ibrpa.com/site/front/v1/guests", {
      method: "POST",
      body: new URLSearchParams(data),
      headers: {
        "content-type": `application/x-www-form-urlencoded`,
      },
    })
      .then(res => res.json())
      .then(body => {
        if (body.code === 0) {
          setOpen(true)
        } else {
          setMessage(body.message)
          console.log(body.message)
        }
      })
  }

  // console.log({ errors })

  return (
    <Layout>
      <Seo title="免费试用" />
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <StaticImage
              className="h-48 w-full object-cover lg:absolute lg:h-full"
              src="../images/trial.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="relative py-8 px-4 sm:py-4 sm:px-6 lg:px-6 lg:max-w-7xl lg:mx-auto lg:py-8 lg:grid lg:grid-cols-2">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                免费试用
              </h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                欢迎使用IBRPA。为了提供更有针对性，更优质的服务，麻烦您填写下面的资料。提交后，我们会把产品的使用手册和下载链接发邮件到您的邮箱。
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    姓名
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="name"
                      {...register("name", { required: true, maxLength: 80 })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.name?.type === "required" && (
                      <div className="text-normal text-red-500">请填写姓名</div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    电话号码
                  </label>
                  <div className="mt-1">
                    <input
                      type="tel"
                      id="phone"
                      {...register("phone", { required: true, maxLength: 20 })}
                      aria-describedby="phone-description"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.phone?.type === "required" && (
                      <div className="text-normal text-red-500">
                        请填写电话号码
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    邮箱
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.email?.type === "required" && (
                      <div className="text-normal text-red-500">请填写邮箱</div>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    公司
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="company"
                      {...register("company", {
                        required: true,
                        maxLength: 100,
                      })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.company?.type === "required" && (
                      <div className="text-normal text-red-500">请填写公司</div>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    行业
                  </label>
                  <div className="mt-1">
                    <select
                      id="industry"
                      {...register("industry", { required: true })}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="0">请选择行业</option>
                      <option value="1">农、林、渔、牧业</option>
                      <option value="2">采矿业</option>
                      <option value="3">制造业</option>
                      <option value="4">电力、热力、燃气及供水行业</option>
                      <option value="5">建筑业</option>
                      <option value="6">批发和零售业</option>
                      <option value="7">交通运输、仓储和邮政业</option>
                      <option value="8">住宿和餐饮业</option>
                      <option value="9">信息传输、软件和信息安全行业</option>
                      <option value="10">金融业</option>
                      <option value="11">房地产行业</option>
                      <option value="12">租赁和商务业</option>
                      <option value="13">科学研究和技术服务业</option>
                      <option value="14">水利、环境和公共设施行业</option>
                      <option value="15">居民服务、修理行业</option>
                      <option value="16">教育行业</option>
                      <option value="17">卫生和社会工作行业</option>
                      <option value="18">文化、体育及娱乐行业</option>
                      <option value="19">公共管理、社会保障行业</option>
                      <option value="20">国际组织</option>
                      <option value="21">军队</option>
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    职位
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="position"
                      {...register("position", { required: true })}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errors.position?.type === "required" && (
                      <div className="text-normal text-red-500">请填写职位</div>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="requirementDesc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      你想使用RPA来解决什么问题？
                    </label>
                    <span
                      id="requirementDesc-description"
                      className="text-sm text-gray-500"
                    >
                      500字以内
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="requirementDesc"
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      {...register("requirementDesc", {
                        required: true,
                        maxLength: 500,
                      })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                    {errors.requirementDesc?.type === "required" && (
                      <div className="text-normal text-red-500">
                        请填写你的问题
                      </div>
                    )}
                  </div>
                </div>

                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    提交
                  </button>
                </div>

                {message && (
                  <div className="text-normal text-red-500">{message}</div>
                )}
              </form>
            </div>
          </div>
        </div>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        欢迎使用IBRPA
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          下载链接已经发送到您的邮箱，请到您的邮箱中下载
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => {
                        setOpen(false)
                        navigate(`/`)
                      }}
                    >
                      确定
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </Layout>
  )
}

export default TrialPage
